import { HighlightLink, ParagraphTitle } from "@/components/Typography";

import { handleLangData } from "@/i18n";
import { media } from "@/utility/mediaQueries";
import styled from "styled-components";
import { useData } from "@/utility/state/contextAPI";

const FooterContainer = styled.div`
  margin-top: auto;
  background-color: #f0e8e7;
  padding: 30px 10px 15px 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  //align-items: center;
  width: 100%;
  margin-bottom: 5rem;

  ${media.small`flex-direction: column`}
`;

const StyledParagraphTitle = styled(ParagraphTitle)`
  //display: flex;
  padding: 10px 0;
  //justify-content: center;
  //align-items: center;
`;

const StyledParagraphWrapper = styled.div`
  padding: 20px;
  max-width: 350px;

  ${media.medium`max-width: 150px`}
  ${media.small`max-width: 350px`}
`;

const CopyrightWrapper = styled.div`
  font-style: italic;
  color: #877878;
`;

const EmailWrapper = styled.p`
  word-wrap: break-word;
  font-style: italic;
`;

const LinkWrapper = styled.div`
  margin: 1rem 0px;
`;

const StyledHighlightLink = styled(HighlightLink)`
  margin: 0px;
`;

function Footer() {
  const {
    base: { i18n },
  } = useData();
  const t = handleLangData(i18n);
  return (
    <FooterContainer>
      <ContentContainer>
        <StyledParagraphWrapper>
          <StyledParagraphTitle>{t["travelBlogs"]}</StyledParagraphTitle>
          <LinkWrapper>
            <StyledHighlightLink
              target="_blank"
              rel="noopener noreferrer"
              to="/Mount-Bromo-Sunrise:-Everything-You-Need-To-Know"
            >
              {t["Mount-Bromo-Sunrise:-Everything-You-Need-To-Know"]}
            </StyledHighlightLink>
          </LinkWrapper>
        </StyledParagraphWrapper>
        <StyledParagraphWrapper>
          <StyledParagraphTitle>
            {t["header.lifeInSingapore"]}
          </StyledParagraphTitle>
          <LinkWrapper>
            <StyledHighlightLink
              target="_blank"
              rel="noopener noreferrer"
              to="/The-Realistic-Cost-of-Living-in-Singapore-in-2023:-A-Practical-Guide"
            >
              {
                t[
                  "The-Realistic-Cost-of-Living-in-Singapore-in-2023:-A-Practical-Guide"
                ]
              }
            </StyledHighlightLink>
          </LinkWrapper>
          <LinkWrapper>
            <StyledHighlightLink
              target="_blank"
              rel="noopener noreferrer"
              to="/Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore"
            >
              {t["Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore"]}
            </StyledHighlightLink>
          </LinkWrapper>
        </StyledParagraphWrapper>
        <StyledParagraphWrapper>
          <StyledParagraphTitle>{t["contactMe"]}</StyledParagraphTitle>
          <EmailWrapper>0529bill@gmail.com</EmailWrapper>
        </StyledParagraphWrapper>
      </ContentContainer>
      <CopyrightWrapper>
        ©bywaterTravel made with love by Lin Tse
      </CopyrightWrapper>
    </FooterContainer>
  );
}

export default Footer;
