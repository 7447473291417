const countryList = [
  {
    name: "Asia",
    child: [
      {
        name: "Singapore",
      },

      {
        name: "Indonesia",
      },
      // {
      //   name: "Thailand",
      // },
    ],
  },
  //   {
  //     name: "Europe",
  //     child: [],
  //   },
];

export default countryList;
