import { createContext, useContext, useReducer } from "react";

import { actionSetI18n } from "./actions";
import { baseReducer } from "./reducer";

const initialBaseData = {
  base: {
    i18n: localStorage.getItem("lang") || "English",
  },
};

export const BaseContext = createContext(initialBaseData);

export const useData = () => {
  const context = useContext(BaseContext);
  if (context === undefined) {
    throw new Error("Something wrong with baseContext");
  }

  return context;
};

export const BaseContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(baseReducer, initialBaseData);

  const contextSetI18n = (lang: string) => {
    // update prompts
    dispatch({
      type: actionSetI18n,
      payload: lang,
    });
  };
  const value = {
    base: {
      i18n: state.base.i18n,
      contextSetI18n: contextSetI18n,
    },
  };

  return <BaseContext.Provider value={value}>{children}</BaseContext.Provider>;
};
