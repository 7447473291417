import {
  HeadingTitle,
  HighLightWrapper,
  HighlightLink,
  ImgTitle,
  ParagraphBlock,
  ParagraphInlineTitle,
  ParagraphTitle,
  SecondHeadingTitle,
  TextBlock,
} from "@/components/Typography";

import { Divider } from "antd";
import DoubleTitleImage from "@/components/shared/DoubleTitleImage";
import TitleImage from "@/components/shared/TitleImage";
import Video from "@/components/shared/Video";
import beach from "/Img/country/Indonesia/Bali/beach.jpg";
import beach2 from "/Img/country/Indonesia/Bali/beach2.jpg";
import bromoJeep from "/Img/country/Indonesia/Bromo/bromoJeep.jpeg";
import bromoVideo from "/Img/country/Indonesia/Bromo/bromoVideo.mp4";
import cangguSign from "/Img/country/Indonesia/Bali/CangguSign.jpg";
import cangguSunset from "/Img/country/Indonesia/Bali/CangguSunset.jpg";
import fireDance from "/Img/country/Indonesia/Bali/fireDance.jpg";
import fireDance2 from "/Img/country/Indonesia/Bali/fireDance2.jpg";
import { handleLangData } from "@/i18n";
import hostel from "/Img/country/Indonesia/Bromo/hostel.jpeg";
import hostelSign from "/Img/country/Indonesia/Bromo/hostelSign.jpeg";
import ijen from "/Img/country/Indonesia/Bromo/ijen.jpeg";
import riceTerrace from "/Img/country/Indonesia/Bali/riceTerrace.jpg";
import tanahLot from "/Img/country/Indonesia/Bali/tanahLot.jpg";
import tanahLotExitSign from "/Img/country/Indonesia/Bali/tanahLotExitSign.jpg";
import templeMe from "/Img/country/Indonesia/Bali/templeMe.jpg";
import volcanoDawn from "/Img/country/Indonesia/Bromo/volcanoDawn.jpeg";
import volcanoWaiting from "/Img/country/Indonesia/Bromo/volcanoWaiting.jpeg";
import waterfall2 from "/Img/country/Indonesia/Bromo/waterfall2.jpeg";
import waterfall3 from "/Img/country/Indonesia/Bromo/waterfall3.jpeg";

const mtBromoTour = (currenLang) => {
  const t = handleLangData(currenLang);
  return (
    <>
      {t["header.intro"]}
      <TextBlock>
        <HighlightLink to="https://tourmountbromo.com/public-tours/">
          {t["header.tourLink"]}
        </HighlightLink>
      </TextBlock>
      <ParagraphBlock>
        <SecondHeadingTitle>
          {t["paragraph.itinerary"]["day1"]}
        </SecondHeadingTitle>
        <TextBlock>
          <ParagraphTitle>
            <div>{t["paragraph.itinerary"]["day1Title"]}</div>
          </ParagraphTitle>
          {t["paragraph.itinerary"]["day1Detail"]}
        </TextBlock>
        <TextBlock>
          <ParagraphTitle>
            <div>{t["paragraph.itinerary"]["day2Title"]}</div>
          </ParagraphTitle>
          {t["paragraph.itinerary"]["day2Detail"]}
        </TextBlock>
        <TextBlock>
          <ParagraphTitle>
            <div>{t["paragraph.itinerary"]["day3Title"]}</div>
          </ParagraphTitle>
          {t["paragraph.itinerary"]["day3Detail"]}
        </TextBlock>
      </ParagraphBlock>

      <HeadingTitle>
        <HighLightWrapper>
          {t["paragraph.itinerary"]["day1Title"]}
        </HighLightWrapper>
      </HeadingTitle>
      <TextBlock>{t["paragraph.day1"]}</TextBlock>
      <TitleImage src={waterfall2} titleImgText={t["images.waterfall3"]} />
      <ParagraphBlock>{t["paragraph.waterfall"]}</ParagraphBlock>
      <TitleImage src={waterfall3} titleImgText={t["images.waterfall3"]} />
      <ParagraphBlock>{t["paragraph.waterfallDetails"]}</ParagraphBlock>
      <TitleImage src={hostelSign} titleImgText={t["images.hostelSign"]} />
      <ParagraphBlock>{t["paragraph.afterWaterfall"]}</ParagraphBlock>
      <TitleImage src={hostel} titleImgText={t["images.hostel"]} />
      <HeadingTitle>
        <HighLightWrapper>
          {t["paragraph.itinerary"]["day2Title"]}
        </HighLightWrapper>
      </HeadingTitle>
      <ParagraphBlock>{t["paragraph.morning"]}</ParagraphBlock>
      <TitleImage
        src={volcanoWaiting}
        titleImgText={t["images.volcanoWaiting"]}
      />
      <ParagraphBlock>{t["paragraph.climbLookout"]}</ParagraphBlock>
      <TitleImage src={volcanoDawn} titleImgText={t["images.volcanoDawn"]} />
      <ParagraphBlock>
        <Video width="400" height={240} controls videoDescription={"mt Bromo"}>
          <source src={bromoVideo} type="video/mp4" />
        </Video>
      </ParagraphBlock>
      <ParagraphBlock>{t["paragraph.sunrise"]}</ParagraphBlock>
      <TitleImage src={bromoJeep} titleImgText={t["images.bromoJeep"]} />
      <HeadingTitle>
        <HighLightWrapper>
          {t["paragraph.itinerary"]["day3Title"]}
        </HighLightWrapper>
      </HeadingTitle>
      <ParagraphBlock>{t["paragraph.afterSunrise"]}</ParagraphBlock>
      <TitleImage src={ijen} titleImgText={t["images.ijen"]} />
      <ParagraphBlock>{t["paragraph.ijenDetails"]}</ParagraphBlock>
      <ParagraphBlock>{t["paragraph.afterIjen"]}</ParagraphBlock>
      <ParagraphBlock>{t["paragraph.summary"]}</ParagraphBlock>
      <TextBlock>
        <HeadingTitle>{t["paragraph.cost"]["title"]}</HeadingTitle>
        <ParagraphBlock>{t["paragraph.cost"]["details"]}</ParagraphBlock>
      </TextBlock>
    </>
  );
};

const baliItinerary = (currLang) => {
  const t = handleLangData(currLang);
  return (
    <TextBlock>
      {t["bali.intro"]}
      <ParagraphBlock>
        <ParagraphTitle>{t["bali.day1to2"]}</ParagraphTitle>
        {t["bali.day1to2.content1"]}
        <HighlightLink to="/Mount-Bromo-Sunrise:-Everything-You-Need-To-Know">
          {t["Here"]}
        </HighlightLink>
        {t["bali.day1to2.content1.additional"]}
        <TextBlock>
          {t["bali.day1to2.content2"]}

          <HighlightLink to="/My-First-Surfing-Adventure-in-Bali-As-An-Beginner">
            {t["thisArticle"]}
          </HighlightLink>

          {t["bali.day1to2.content2.additional"]}
        </TextBlock>
        {t["bali.day1to2.content3"]}
      </ParagraphBlock>

      <ParagraphBlock>
        <TextBlock>
          <ParagraphTitle>{t["bali.day3"]}</ParagraphTitle>
          {t["bali.day3.content1"]}

          <TitleImage src={templeMe} titleImgText={t["bali.image.goaGajah"]} />
          <TextBlock>{t["bali.day3.content2"]}</TextBlock>

          <TitleImage
            src={riceTerrace}
            titleImgText={t["bali.image.riceTerrace"]}
          />
          <TextBlock>{t["bali.day3.content3"]}</TextBlock>
          <TextBlock>{t["bali.day3.content4"]}</TextBlock>
        </TextBlock>
      </ParagraphBlock>

      <ParagraphBlock>
        <TextBlock>
          <TitleImage
            src={cangguSign}
            titleImgText={t["bali.image.cangguSign"]}
          />
          <ParagraphTitle>{t["bali.day4to5"]}</ParagraphTitle>

          {t["bali.day4to5.content1"]}
          <TitleImage
            src={cangguSunset}
            titleImgText={t["bali.image.cangguSunSet"]}
          />
          <TextBlock>{t["bali.day4to5.content2"]}</TextBlock>
          <TitleImage src={tanahLot} titleImgText={t["bali.image.tanahlot"]} />
        </TextBlock>
      </ParagraphBlock>

      <ParagraphBlock>
        <TextBlock>
          <TitleImage src={beach} titleImgText={t["bali.image.padangpadang"]} />
          <ParagraphTitle>{t["bali.day6"]}</ParagraphTitle>
          {t["bali.day6.content1"]}
          <TitleImage
            src={beach2}
            titleImgText={t["bali.image.dreamlandBeach"]}
          />
          <TextBlock>{t["bali.day6.content2"]}</TextBlock>
          <TitleImage
            src={fireDance}
            titleImgText={t["bali.image.FireDance"]}
          />
        </TextBlock>
      </ParagraphBlock>

      <ParagraphBlock>
        <TextBlock>
          <ParagraphTitle>{t["bali.day7to8"]}</ParagraphTitle>
          {t["bali.day7to8.content"]}
        </TextBlock>
      </ParagraphBlock>
      <Divider />

      <ParagraphBlock>
        <SecondHeadingTitle>{t["bali.itinerary.day1to2"]}</SecondHeadingTitle>
        <TextBlock>
          <ParagraphTitle>{t["bali.itinerary.day1to2.title"]}</ParagraphTitle>
          {t["bali.itinerary.day1to2.content1"]}
        </TextBlock>

        <TextBlock>
          <ParagraphTitle>{t["bali.itinerary.day3"]}</ParagraphTitle>
          {t["bali.itinerary.day3.content1"]}
        </TextBlock>

        <TextBlock>
          <ParagraphTitle>{t["bali.itinerary.day4to5"]}</ParagraphTitle>
          {t["bali.itinerary.day4to5.title"]}
          {t["bali.itinerary.day4to5.content1"]}
        </TextBlock>

        <TextBlock>
          <ParagraphTitle>{t["bali.itinerary.day6"]}</ParagraphTitle>
          {t["bali.itinerary.day6.title"]}
        </TextBlock>
      </ParagraphBlock>

      <TextBlock>{t["bali.conclusion"]}</TextBlock>
    </TextBlock>
  );
};

const baliSurf = (currLang) => {
  const t = handleLangData(currLang);

  return (
    <>
      {t["bali.surf.intro"]}
      <ParagraphBlock>
        <SecondHeadingTitle>{t["bali.surf.kuta"]}</SecondHeadingTitle>
        {t["bali.surf.kutaContent1"]}
      </ParagraphBlock>
      <ParagraphBlock>
        <SecondHeadingTitle>{t["bali.surf.canggu"]}</SecondHeadingTitle>
        {t["bali.surf.cangguContent1"]}
      </ParagraphBlock>
      {t["bali.surf.conclusion"]}
    </>
  );
};
export { mtBromoTour, baliItinerary, baliSurf };
