import React from "react";
import { Select } from "antd";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  width: 100px;

  & .ant-select-selector {
    font-size: 1rem;
    font-weight: bold;

    border-radius: 110px;
  }

  /* &.ant-select-dropdown .ant-select-item {
    font-weight: bold;
  }

  &.ant-select-selection-item {
    font-weight: bold;
  } */
  &.ant-select .ant-select-selection-item {
    font-weight: bold;
  }

  &.ant-selector {
  }

  .ant-select-selector {
    border-color: #d9d9d9 !important;
    &:hover {
      border-color: #d9d9d9 !important;
    }

    &:focus {
      border-color: #d9d9d9 !important;
    }
  }
`;

const AntdSelect: React.FC = ({ handleChange, options, ...rest }) => {
  return (
    <StyledSelect
      defaultValue={options[0]}
      onChange={handleChange}
      options={options}
      {...rest}
    />
  );
};

export default AntdSelect;
