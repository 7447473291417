import styled from "styled-components";

const StyledVideo = styled.video`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  display: block;
  margin: auto;
`;

const VideoDescription = styled.div`
  font-weight: bold;
  font-style: italic;
  text-align: center;
`;

function Video({ videoDescription, children, ...rest }) {
  return (
    <>
      <StyledVideo {...rest}>{children}</StyledVideo>
      {videoDescription ? (
        <VideoDescription>{videoDescription}</VideoDescription>
      ) : null}
    </>
  );
}

export default Video;
