import styled, { css } from "styled-components";

import { Skeleton } from "antd";
import { media } from "@/utility/mediaQueries";
import { useState } from "react";

const TitleImageText = styled.div`
  font-weight: bold;
  font-style: italic;
`;

const TitleImage = styled.img`
  //max-width: 700px;
  object-fit: contain;
  border-radius: 8px;

  /* ${media.medium`max-width: 450px;`};

  ${media.small`max-width: 250px;`};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth} !important;
    `} */
`;

const TitleContainer = styled.div`
  margin: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

const TitleImageContainer = styled.div`
  ${({ isLoad }) =>
    !isLoad &&
    css`
      width: 0px;
      height: 0px;
      font-size: 0;
    `}
`;

function TitleImg({ src, titleImgText, maxWidth }) {
  let [isLoad, setIsLoad] = useState(false);

  function handleSetIsLoad() {
    setIsLoad(true);
  }

  console.log("isLoad", isLoad);
  return (
    <TitleContainer>
      {!isLoad && (
        <>
          <Skeleton.Button active size="small" shape="round" block={true} />
          <br />
          <Skeleton.Button active size="small" shape="round" block={true} />
          <br />
          <Skeleton.Button active size="small" shape="round" block={true} />
        </>
      )}
      <TitleImageContainer isLoad={isLoad}>
        <TitleImage onLoad={handleSetIsLoad} maxWidth={maxWidth} src={src} />
        <TitleImageText>{titleImgText}</TitleImageText>
      </TitleImageContainer>
    </TitleContainer>
  );
}

export default TitleImg;
