import { Outlet, useLocation } from "react-router-dom";

import Footer from "@/pages/Footer";
import Header from "@/components/header";
import { media } from "@/utility/mediaQueries";
import styled from "styled-components";
import { useData } from "@/utility/state/contextAPI";
import { useEffect } from "react";

const MainContainer = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BaseStylingContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ContentContainer = styled.div`
  padding: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
`;

function App() {
  let location = useLocation();

  const {
    base: { i18n },
    lang,
  } = useData();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window.scroll(0, 0);
  }, [location]);

  return (
    <BaseStylingContainer>
      <MainContainer>
        <Header />
        <ContentContainer>
          <Outlet />
        </ContentContainer>
        <Footer />
      </MainContainer>
    </BaseStylingContainer>
  );
}

export default App;
