import { Link } from "react-router-dom";
import { media } from "@/utility/mediaQueries";
import styled from "styled-components";

const HeadingTitle = styled.p`
  font-size: 2rem;
  font-weight: bold;
  line-height: normal;
`;

const SecondHeadingTitle = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: normal;
`;

const ParagraphTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  line-height: normal;
`;

const ParagraphInlineTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

const ImgTitle = styled.p`
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Roboto Slab", serif;

  ${media.small` font-size: 1rem;`};
`;

const ParagraphBlock = styled.div`
  margin: 1rem;
`;

const TextBlock = styled.div`
  margin: 1rem 0px;
`;

const HighlightLink = styled(Link)`
  margin: 0px 0.3rem;
  background-image: linear-gradient(transparent calc(100% - 0.45em), #c0e2e2 0);
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(
      transparent calc(100% - 0.45em),
      #6d937e 0
    );
  }
`;

const HighLightWrapper = styled.span`
  margin: 0px 0.3rem;
  background-image: linear-gradient(transparent calc(100% - 1rem), #fec8f5 0);
`;

export {
  HeadingTitle,
  ParagraphTitle,
  ImgTitle,
  ParagraphBlock,
  TextBlock,
  ParagraphInlineTitle,
  HighlightLink,
  SecondHeadingTitle,
  HighLightWrapper,
};
