import {
  HeadingTitle,
  ParagraphInlineTitle,
  SecondHeadingTitle,
} from "@/components/Typography";

import CountryDetailedList from "@/localData/countryDetailedList";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import TitleImg from "@/components/shared/TitleImage";
import { handleLangData } from "@/i18n";
import hiltonView from "/Img/country/Singapore/hiltonView.jpg";
import { media } from "@/utility/mediaQueries";
import sgCityLine from "/Img/country/Singapore/sgCityLine.jpeg";
import styled from "styled-components";
import { useData } from "@/utility/state/contextAPI";

const CountryMainContainer = styled.div`
  width: 1080px;
  height: 100%;
`;

const TitleAreaContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const ContentAreaContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  ${media.small`flex-direction: column`};
`;

const ArticleContainer = styled.div`
  flex: 70%;
  padding: 1.5rem 2rem;

  ${media.small`padding: 1.5rem 0rem;`};
`;
const SideContainer = styled.div`
  flex: 30%;
  padding: 1.5rem 2rem;

  ${media.small`padding: 1.5rem 0rem;`};
`;

const TitleImageContainer = styled.div`
  margin: 1rem 1rem 3rem 1rem;
`;

const TitleTextContainer = styled.div`
  margin: 2rem;
`;

const svgDataURL =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' preserveAspectRatio='none'%3E%3Cpath d='M13.7...';/%3E%3C/svg%3E%0A";
const TitleImageCover = styled.div`
  mask-image: url(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' preserveAspectRatio='none'%3E%3Cpath d='M13.7.3C12-.1 10-.2 8.3.6c-1.3.7-2.2 1.7-3.1 3-1.3 1.9-2.7 3.8-3.5 6.1C.8 12 .5 14.5.3 17c-.2 2.2-.4 4.5-.2 6.8s1.1 4.5 2.6 6c2.9 2.8 9.5 2.5 13.3 1.8 3.1-.5 6.2-1 9-2.5 3.5-1.8 4.4-5.1 5.7-9.1 1.6-4 2.3-13.1-1.5-15.6-4.5-2.9-10.3-3-15.5-4.1z'/%3E%3C/svg%3E%0A"));
  -webkit-mask-image: url(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org…5-15.6-4.5-2.9-10.3-3-15.5-4.1z'/%3E%3C/svg%3E%0A"));
  width: 100px;
  height: 100px;
`;

const TitleImage = styled.img`
  max-width: 700px;
  height: 100%;
  border-radius: 8px;

  ${media.medium`max-width: 450px;`};

  ${media.small`max-width: 250px;`};
`;

const TitleImageText = styled.div``;

const SideTitle = styled.div``;

const SideContentContainer = styled.div`
  margin: 2rem 0px;
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  max-height: 76px;

  &:hover {
    cursor: pointer;
    background-color: #f5f4f2;
  }
`;

const StyledImg = styled.img`
  max-width: 90px;
  max-height: 60px;
  margin-right: 10px;
  border-radius: 9px;
`;

const ContentTextWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
`;

function CountryMainTemplate({ countryData }) {
  const {
    base: { i18n },
  } = useData();
  const t = handleLangData(i18n);

  return (
    <>
      <Helmet>
        <title>{t[countryData.title]}</title>
        <meta name="description" content={t[countryData.title]} />
      </Helmet>

      <CountryMainContainer>
        <TitleAreaContainer>
          <TitleTextContainer>
            <HeadingTitle>{t[countryData.title]}</HeadingTitle>
          </TitleTextContainer>
          <TitleImageContainer>
            <TitleImg
              src={countryData.img}
              titleImgText={t[countryData.titleImgText]}
            />
          </TitleImageContainer>
        </TitleAreaContainer>
        <ContentAreaContainer>
          <ArticleContainer>{countryData.content(i18n)}</ArticleContainer>
          <SideContainer>
            <SideTitle>
              <SecondHeadingTitle>
                {t["countryMainTemplate.checkoutOtherPosts"]}
              </SecondHeadingTitle>
            </SideTitle>
            <SideContentContainer>
              {CountryDetailedList.filter(
                (data) => data.location !== countryData.location
              ).map((currData) => {
                return (
                  <Link to={currData.link}>
                    <ContentWrapper>
                      <>
                        <StyledImg src={currData.img} />
                        <ContentTextWrapper>
                          {t[currData.title]}
                        </ContentTextWrapper>
                      </>
                    </ContentWrapper>
                  </Link>
                );
              })}
            </SideContentContainer>
          </SideContainer>
        </ContentAreaContainer>
      </CountryMainContainer>
    </>
  );
}

export default CountryMainTemplate;
