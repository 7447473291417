import { JobFindingInSG, SingaporeLiving } from "./ArticleList/Singapore";
import { baliItinerary, baliSurf, mtBromoTour } from "./ArticleList/Indonesia";

import MBS from "/Img/country/Singapore/MBS.jpg";
import hiltonView from "/Img/country/Singapore/hiltonView.jpg";
import sgCityLine from "/Img/country/Singapore/sgCityLine.jpeg";
import surfBali from "/Img/country/Indonesia/Bali/surf.webp";
import templePraying from "/Img/country/Indonesia/Bali/templePraying.jpg";
import volcano from "/Img/country/Indonesia/Bromo/volcano.jpeg";
import volcano2 from "/Img/country/Indonesia/Bromo/volcano2.jpeg";

const CountryDetailedList = [
  //Singapore
  {
    img: sgCityLine,
    title: "Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore",
    titleImgText:
      "Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore_ImgText",
    location: "Singapore",
    link: "/Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore",
    content: JobFindingInSG,
  },

  {
    img: hiltonView,
    title:
      "The-Realistic-Cost-of-Living-in-Singapore-in-2023:-A-Practical-Guide",
    titleImgText:
      "The-Realistic-Cost-of-Living-in-Singapore-in-2023:-A-Practical-Guide_ImgText",
    location: "Singapore",
    link: "/The-Realistic-Cost-of-Living-in-Singapore-in-2023:-A-Practical-Guide",
    content: (currentLang) => SingaporeLiving(currentLang),
  },

  //Indonesia
  {
    img: volcano,
    title: "Mount-Bromo-Sunrise:-Everything-You-Need-To-Know",
    titleImgText: "Mount-Bromo-Sunrise:-Everything-You-Need-To-Know_ImgText",
    location: "Indonesia",
    link: "/Mount-Bromo-Sunrise:-Everything-You-Need-To-Know",
    content: mtBromoTour,
  },
  {
    img: templePraying,
    title: "My-One-Week-Solo-Trip-to-Bali:-Temples,-Surfing,-and-Beaches",
    titleImgText:
      "My-One-Week-Solo-Trip-to-Bali:-Temples,-Surfing,-and-Beaches_ImgText",
    location: "Indonesia",
    link: "/My-One-Week-Solo-Trip-to-Bali:-Temples,-Surfing,-and-Beaches",
    content: baliItinerary,
  },

  {
    img: surfBali,
    title: "My-First-Surfing-Adventure-in-Bali-As-An-Beginner",
    titleImgText: "My-First-Surfing-Adventure-in-Bali-As-An-Beginner_ImgText",
    location: "Indonesia",
    link: "/My-First-Surfing-Adventure-in-Bali-As-An-Beginner",
    content: baliSurf,
  },
];

export default CountryDetailedList;
