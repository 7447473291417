import { actionSetI18n } from "./actions";

export const baseReducer = (state, action) => {
  switch (action.type) {
    case actionSetI18n: {
      localStorage.setItem("lang", action.payload);
      return {
        ...state,
        base: {
          ...state.base,
          i18n: action.payload,
        },
      };
    }
    default:
      console.warn(`CANT FIND RESPONDED REDUCER: ${action.type}`);
      return;
  }
};
