import en from "./en.json";
import tw from "./tw.json";

const langData = { English: en, Chinese: tw };

const handleLangData = (lang) => {
  if (langData[lang]) {
    return langData[lang];
  } else {
    console.warn("wrong i18n lang");
    return langData["English"];
  }
};

export { handleLangData };
