import {
  HeadingTitle,
  ParagraphBlock,
  TextBlock,
} from "@/components/Typography";

import { handleLangData } from "@/i18n";
import { media } from "@/utility/mediaQueries";
import selfie from "/Img/selfie.png";
import styled from "styled-components";
import { useData } from "@/utility/state/contextAPI";

const MainContainer = styled.div`
  padding: 0px 150px;
  display: flex;
  width: 1080px;
  ${media.small`flex-direction: column; padding: 0px 0px`}
`;

const ImgContainer = styled.div``;
const AboutMeTextContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  padding: 30px;
  ${media.small` padding: 10px`}
`;

const StyledImg = styled.img`
  width: 200px;
  height: 200px;
`;

function AboutMe() {
  const {
    base: { i18n },
  } = useData();

  const t = handleLangData(i18n);
  return (
    <MainContainer>
      <ImgContainer>
        <StyledImg src={selfie} alt="selfie" />
      </ImgContainer>
      <AboutMeTextContainer>
        <HeadingTitle>{t["aboutMe.title1"]}</HeadingTitle>
        <TextBlock>
          {t["aboutMe.p1"]}
          <TextBlock>{t["aboutMe.p2"]}</TextBlock>
          {t["aboutMe.p3"]}
          <TextBlock>{t["aboutMe.p4"]}</TextBlock>
          {t["aboutMe.p5"]}
        </TextBlock>
      </AboutMeTextContainer>
    </MainContainer>
  );
}

export default AboutMe;
